import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/header'

import styles from './scholarship-page.module.css'

class ScholarshipPageTemplate extends React.Component {
  render() {
    const pageData = get(this.props, 'data.contentfulScholarshipAward')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div className={'pageContent'}>
          <Helmet title={`${pageData.title} - ${siteTitle || 'Zonta Club of Toronto'}`} />
          <Header
            backgroundVideo={pageData.heroVideo}
            backgroundImage={pageData.heroImage}
            title={pageData.title}
            description={pageData.description}
          />
          <div className="wrapper">
            <div
              className="body-markup"
              dangerouslySetInnerHTML={{
                __html: pageData.body.childMarkdownRemark.html,
              }}
            />
            <Link to={`/scholarships`} className={`cta textLink primary iconLink iconLeft backArrow ${styles.backCta}`}>All Scholarships and Awards</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ScholarshipPageTemplate

export const pageQuery = graphql`
  query ScholarshipAwardBySlug($slug: String!) {
    contentfulScholarshipAward(slug: { eq: $slug }) {
      heroImage {
        description
        fluid {
          src
          srcSetWebp
          srcSet
        }
        file {
          contentType
        }
      }
      heroVideo {
        file {
          url
          contentType
        }
        description
        title
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      title
      slug
      zontaInternational
    }
  }
`
